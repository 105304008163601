import { Event } from 'bagad-client';
import Badge from '../../../components/badge';
import Tooltip from '../../../components/tooltip';
import EventCategories from '../../../utils/event-category';
import groupBy from '../../../utils/groupby';

function* generator(monthOffset: number, dayOffset = 1) {
  const today = new Date();
  const currentMonth = today.getMonth() + monthOffset;
  const firstDay = new Date(Date.UTC(today.getFullYear(), currentMonth, 1));
  const lastDay = new Date(Date.UTC(today.getFullYear(), currentMonth + 1, 0));
  firstDay.setDate(firstDay.getDate() - ((firstDay.getDay() - dayOffset + 7) % 7));
  lastDay.setDate(lastDay.getDate() + ((7 - lastDay.getDay() - 1 + dayOffset) % 7));
  while (firstDay <= lastDay) {
    yield [
      new Date(firstDay),
      currentMonth % 12 === firstDay.getMonth(),
      firstDay.getDate() === today.getDate()
      && firstDay.getMonth() === today.getMonth()
      && firstDay.getFullYear() === today.getFullYear(),
    ] as [Date, boolean, boolean];
    firstDay.setDate(firstDay.getDate() + 1);
  }
}

const days = ['lun', 'mar', 'mer', 'jeu', 'ven', 'sam', 'dim'];

interface CalendarProps extends React.ComponentPropsWithoutRef<'div'> {
  events: Event[]
  monthOffset?: number
  displayContent?: boolean
}

export default function Calendar({
  events,
  monthOffset = 0,
  displayContent = false,
}: CalendarProps) {
  const eventsByDate = groupBy(events, (item) => item.date.toISOString().substring(0, 10));

  return (
    <div>
      <div className="grid grid-cols-7">
        {days.map((day) => <div key={day} className="font-bold uppercase text-center">{day}</div>)}
      </div>
      <div className="grid grid-cols-7 gap-px bg-gray-200 border border-gray-200">
        {Array.from(generator(monthOffset)).map(([day, isCurrentMonth, isToday]) => (
          <Tooltip
            key={day.getTime()}
            content={
              eventsByDate.get(day.toISOString().substring(0, 10))?.map((event) => (
                <div key={event.id} className="py-1">
                  <div className="font-bold">{event.title}</div>
                  <div className="1">{event.description ? event.description : 'Pas de description'}</div>
                </div>
              ))
            }
            as="div"
          >
            <div
              className={`bg-white aspect-square flex flex-col items-center p-1 ${isCurrentMonth ? '' : 'opacity-50'}`}
            >
              <div className={`inline-flex justify-center items-center h-8 w-8 m-1 rounded-full ${isToday ? ' bg-pourpre-400 text-white' : ''} ${eventsByDate.get(day.toISOString().substring(0, 10))?.length ? 'border border-pourpre-400' : ''}`}>
                {day.getDate()}
              </div>
              {displayContent && (
                <div className="hidden md:block w-full">
                  {eventsByDate.get(day.toISOString().substring(0, 10))?.slice(0, 2).map(
                    (event) => (
                      <Badge key={event.id} color={EventCategories[event.category]?.bg ?? 'bg-gray-500'} className="block mb-px rounded-sm truncate text-ellipsis w-full">
                        {event.title}
                      </Badge>
                    ),
                  )}
                  <div className="pl-2 pt-1 text-sm">
                    {eventsByDate.get(day.toISOString().substring(0, 10))?.slice(2).length ? '+1' : ''}
                  </div>
                </div>
              )}
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  );
}
